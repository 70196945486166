<template>
  <div class="wrapper">
    <parallax class="page-header header-filter" :style="headerStyle">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand">
<!--              <h1>Shermans Dale Ambulance Association</h1>-->
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">

      <div class="section" :style="bgIntroImage" id="intro">
        <div class="container text-center">
          <div class="md-layout">
            <div id="intro-text"
              class="md-layout-item md-size-66 md-xsmall-size-100 ml-auto mr-auto text-center"
            >
              <h1>Emergency Medical Services</h1>
              <div style="background: #000; opacity: 0.7;" >
                <h3>
                  SDAA provides emergency medical services to Carroll Township and serves as a center for community events and banquet hall rental.
                </h3>
              </div>
<!--              <img :src="introImage" width="800">-->
            </div>
          </div>
        </div>
      </div>

      <div class="section" id="upcoming-events" >
        <div class="text-center"  >
          <div >
            <img id="event-img" :src="upComingEventImage" style="background-size: cover; width: 100%;">
          </div>
          <div class="container">
<!--            <h1 class="header-upcoming-event">Up Coming Events</h1>-->
            <div class="img-container">
  <!--              <img :src="image" alt="event-image">-->
            </div>
            <div class="event-details-container" v-for="event in events" :key="event.title">

                <h2 class="event-title">{{ event.title }}</h2>
                <h4 class="event-date">{{ event.date }}</h4>
                <p class="event-description">{{event.description}}</p>
                 <img :src="event.image" width="800">
                <hr>
            </div>
          </div>
        </div>
      </div>


      <div class="section section-signup page-header" :style="signupImage">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-33 md-medium-size-40 md-small-size-50 md-xsmall-size-70 mx-auto text-center"
            >
              <login-card header-color="red">
                <h4 slot="title" class="card-title">Contact Us</h4>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>face</md-icon>
                  <label>First Name...</label>
                  <md-input v-model="firstname"></md-input>
                </md-field>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>email</md-icon>
                  <label>Email...</label>
                  <md-input v-model="email" type="email"></md-input>
                </md-field>
                <md-field class="md-form-group" slot="inputs">
                  <label>Tell Us How We Can Help</label>
                  <md-textarea v-model="description"></md-textarea>
                </md-field>
                <md-button slot="footer" class="md-simple md-success md-lg" @click="sendMessage">
                  Send
                </md-button>
              </login-card>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="md-layout">
        <div class="md-layout-item text-center">
          <md-button href="#/login" class="md-simple md-success md-lg"
            >View Login Page</md-button
          >
        </div>
      </div>
      <div class="section section-examples">
        <div class="container-fluid text-center">
          <div class="md-layout">
            <div class="md-layout-item">
            </div>
          </div>
        </div>
      </div> -->
      <div class="section section-download" id="downloadSection">
        <div class="container">
          <!-- <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
            </div>
          </div> -->
          <!-- <br /><br /> -->

          <!-- <div class="md-layout text-center">
            <div class="md-layout-item md-size-66 md-small-size-100 mx-auto">
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
              <div class="btn-container">
              </div>
            </div>
          </div> -->
          <div>
            <img :src="bottomImage" >
          </div>

          <div class="sharing-area text-center">
            <div class="md-layout">
              <div class="md-layout-item">
                <h3>Find Us On Social Media To Stay Up-To-Date</h3>
              </div>
            </div>

            <md-button class="md-facebook" href="https://www.facebook.com/shermansdaleambulanceassociation/"
              ><i class="fab fa-facebook-square"></i> Share</md-button
            >
          </div>
          <div id="contact-info">
            <h5><b>Station Phone Number is:</b> (717) 582-2732</h5>
            <h5><b>Email us @:</b>  shermansdaleambulance129@gmail.com</h5>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginCard } from "@/components";
import emailjs from '@emailjs/browser';

export default {
  components: {
    LoginCard
  },
  name: "index",
  bodyClass: "index-page",
  props: {
    image: {
      type: String,
      default: require("@/assets/img/SDAAIntroImage.png")
    },
    landing: {
      type: String,
      default: require("@/assets/img/landing.jpg")
    },
    profile: {
      type: String,
      default: require("@/assets/img/city-profile.jpg")
    }
  },
  data() {
    return {
      firstname: null,
      email: null,
      description: null,
      leafShow: false,
      upComingEventImage: require("@/assets/img/UpcomingEventsUpdate.png"),
      introImage: require("@/assets/img/medicalServiceLogo.jpg"),
      bottomImage: require("@/assets/img/fullSideAmbulanceImage.jpg"),
      events: [
      {
        image:require("@/assets/img/2023/sdaa_ham_green_beans_dinner_oct_21st.jpg"),
        title:"Ham & Green Beans Dinner",
        date: "Saturday October 21st 3:00pm to 6:00pm",
        description: "big old dinner bowl of the best ham & green beans this side of the Mississippi."
       },
      {
        image:require("@/assets/img/2023/sdaa_ham_green_beans_dinner_oct_21st.jpg"),
        title:"Trunk or Treat",
        date: "Friday October 27th, 2023",
        description: "Getting down in candy town, join us in the parking lot for a lot full of fun."
       },
       {
        image:require("@/assets/img/2023/sdaa_pork_sauerdraut_dinner_nov_11th.jpg"),
        title:"Pork & Sauerkraut Dinner",
        date: "Saturday November 11th",
        description: "Krauter your Saut with us on this fine day."
       },
        {
          image:require("@/assets/img/2023/sdaa_grocery_food_auction_nov_17th.jpg"),
          title: "Grocery/Food Auction",
          date: "Friday November 17th",
          description: "Bring a cooler, a friend or family and pop lock and drop a stock for your fridge and freezer."
        },
        {
          image:require("@/assets/img/2023/sdaa_night_owl_t_nov_18th.jpg"),
          title: "Night Owl-T",
          date: "Saturday November 18th",
          description: "We are winter gnoming it up, you gnome what I'm saying"
        },
        {
          image:require("@/assets/img/2023/sdaa_breakfast_with_santa.jpg"),
          title: "Breakfast with SANTA!",
          date: "Saturday December 16th",
          description: "It's freaking Santa, who isn't going be there. Better get their early before Santa eats all the bacon."
        },
        // {
        //   image:require("@/assets/img/chickenPotPie.jpg"),
        //   title:"Chicken Pot Pie",
        //   date: "Saturday November 12th",
        //   description: ""
        // }
        // {
        //   image: require("@/assets/img/FleaMarket.png"),
        //   title: "SPRING FLEA MARKET",
        //   date: "Saturday June 4th",
        //   description: "Annual Spring Flea Market to be held in our large parking\n" +
        //       "lot with lots of vendors, food & drinks on site. Raffles and\n" +
        //       "\n" +
        //       "Rip Tickets will be held throughout the day.\n" +
        //       "\n" +
        //       "Reserve your space(s) for the event by contacting JC Smith\n" +
        //       "\n" +
        //       "at 717-275-1222.\n" +
        //       "\n" +
        //       "Spaces are 10x20 in size and are $10.00 in the grass or\n" +
        //       "\n" +
        //       "$20.00 on the macadam lot.",
        // }
      ],
    };
  },
  methods: {
      sendMessage() {
          const email = this.checkEmail()
          if(!email){
            alert('Please enter a valid email')
          } else {

          emailjs.send("service_bisbzpt","template_e3gr33d",{        
            from_name: this.firstname,
            message: this.description,
            reply_to: this.email,
            }, "nntSRGj28rpJmkSMC" )
            .then(
              (result) => {
                alert('Message Sent!')
              },
              (error) => {
                alert('Message Failed!')
              }
            );

          this.firstname = "";
          this.email = "";
          this.description = "";

          }  
          
      },

      checkEmail(){
        return this.email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      }  
      
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`,
        backgroundRepeat: "no-repeat"
      }
    },
    signupImage() {
      return {
        backgroundImage: `url(${this.signup})`
      };
    },
    bgIntroImage() {
      return {
        backgroundImage: `url(${this.introImage})`
      }
    },
    upComingEventsBGImage() {
      return {
        backgroundImage: `url(${this.upComingEventImage})`
      }
    }
  },

};
</script>
<style lang="scss">
.section-download {
  .md-button + .md-button {
    margin-left: 5px;
  }
}

@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}

.event-details-container {
  margin-bottom: 20px;
}

.header-upcoming-event {
  text-decoration: underline;
}

.event-title {
  font-weight: bold;
}

#intro {
  height: 600px;
  background-position: center;
  background-size: cover;
}

#intro-text {
  color: white;
  font-weight: 700;
}

#event-img {

}

#upcoming-events {
      color: #00299e;


}

#contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px !important;
}

.section{
  padding-top: 0 !important;
}

</style>
